<template >
  <div
      class="login p-d-flex  p-jc-center p-ai-center"
      style="height:100%; direction: rtl"
  >
    <Card class="card-login p-m-4">
      <template #header>
        <img
		   class="p-p-4"
            :src="imageUrl"
            :title="$t('login.subTitle')"
        />
		<Divider class="p-p-0 p-m-0"></Divider>
      </template >
      <template #title> {{ $t("login.title") }}</template >
      <template #subtitle>
        <p >{{ $t("login.subTitle") }}</p >
      </template >
      <template #content>
        <div class="p-fluid">
          <div class="p-p-1 p-field">
            <span class="p-float-label">
              <InputText
                  id="usernsme"
                  v-model="user_name"
              />
              <label for="usernsme">{{ $t("login.username") }}</label >
            </span >
          </div >
          <div class="p-p-1 p-field">
            <span class="p-float-label">
              <Password
                  id="password"
                  v-model="password"
                  :toggleMask="true"
                  :feedback="false"
              />

              <label for="password">{{ $t("login.password") }}</label >
            </span >
          </div >
          <div class="p-p-1 p-m-0 p-field">
            <Button
                :label="$t('login.login')"
                @click="login"
                :disabled="(!password  || !user_name )"
            />
          </div >
        </div >
      </template >
    </Card >
  </div >
</template >

<script >
import {login} from "./Login.js";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import constants from "@/constants";

export default {
  name: "Login",
  components: {},
  methods: {
    ...mapActions([
                    "setAccessToken",
                    "setRole",
                    "setUser"
                  ]),
    async login() {

      try {
        const {data} = await login({
                                                    user_name: this.user_name,
                                                    password: this.password
                                                  });
        localStorage.setItem("token", data.access_token)
        // axios.defaults.headers.common['token'] = data.access_token
        // this.setAccessToken(data.access_token)
        // this.setRole(data.type)
        this.$router.go("/suggestions")
        // data.type === 'מנהל' ? this.$router.push("/presence") :
        //     this.$router.push("/colel-settings")
      } catch (e){
        let errorDetail = 'login.loginError'
        if (e.response?.status === 403){
          errorDetail += 'Forbidden'
        } else {
          errorDetail += 'Details'
        }
        e.response?.status
        this.$toast.add({
                          severity: 'error',
                          summary: this.$t('login.loginErrorTitle'),
                          detail:  this.$t(errorDetail),
                          life: constants.LIFE_TOAST
                        });
      }
    }
  },

  data() {
    return {
      user_name: null,
      password: null
    };
  },
  computed: {
    imageUrl() {
      return require(`${process.env.VUE_APP_IMAGE_LOGIN}`);
    },
  }
};
</script >

<style scoped>
>>>.p-card-header{
    background: var(--primary-color-700) !important;

}

/deep/ .p-card-title {
    text-align: center;
}

>>> .p-card-subtitle {
    text-align: center;
}

.p-float-label > label {
    right: 0.5rem !important;
}

.login {
    background: var(--primary-color) !important;
}

/*@media screen and (min-width: 1025px) {*/
/*    .card-login {*/
/*        width: 30vw !important;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 1024px) {*/
/*    .card-login {*/
/*        width: 35vw !important;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 768px) {*/
/*    .card-login {*/
/*        width: 40vw !important;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 480px) {*/
/*    .card-login {*/
/*        width: 50vw !important;*/
/*    }*/
/*}*/

>>> .p-input-icon-right > i:last-of-type {
    left: 0.5rem !important;
    right: auto !important;
}

>>> .p-input-icon-right > .p-inputtext {
    padding-right: 0.75rem !important;
}
</style >
